import { Button } from "../button";
import { Faq } from "../faq";

const faqItems = [
  {
    title: "When will the course be finished?",
    content: () => (
      <>
        <p>
          The first <strong>five</strong> modules of the course are out NOW. So
          no need to wait to start learning! Every few weeks there will be new
          modules added to the course. If you're a member, you will receive an
          email when new content is released too!
        </p>
      </>
    ),
  },
  {
    title: "What knowledge do you expect me to have when starting this course?",
    content: () => (
      <>
        <ul className="ml-5 list-disc space-y-3">
          <li>
            Motion for React: No knowledge required. I take you by the hand and
            teach you everything step by step.
          </li>
          <li>
            React: You should be familiar with React and know at least how to
            create a component.
          </li>
          <li>
            HTML & CSS: I won't teach you many HTML or CSS semantics. It's
            important you know how to work with HTML, and style rectangles on
            the page. You don't need to know any advanced CSS tricks, or a lot
            about CSS animations.
          </li>
        </ul>
        <p>
          Also make sure to check the free introduction lesson of this course.
          This lesson dives a bit more into what you will learn, and the
          knowledge I expect you to already have.
        </p>
        <Button
          href="https://www.frontend.fyi/course/framer-motion/01-introduction/01-introduction"
          intent="white"
          size="small"
          className="no-underline"
          target="_blank"
        >
          Go to the introduction lesson lesson
        </Button>
      </>
    ),
  },
  {
    title: "How long do I have access to the course?",
    content: () => (
      <p>
        For ever. The course is part of the{" "}
        <a
          href="https://www.frontend.fyi/pro"
          className="underline"
          target="_blank"
        >
          PRO plan
        </a>
        , which is a one-time purchase that gives you access to this course, and
        any other courses released in the future.
      </p>
    ),
  },
  {
    title: "What type of content can I expect? Video, text, exercises?",
    content: () => (
      <>
        <p>
          This course will feature both text and video lessons. But it won't
          stop there. The whole course platform is custom built by me, and
          centered around a live playground. This live playground is what you
          will use to learn most of the skills, and explore them on your own. It
          is also what you will be using to build the excersises in!
        </p>
        <p>
          Watch the second lesson of the course for free below. It showcases all
          the features the platform will bring!
        </p>
        <Button
          href="https://www.frontend.fyi/course/framer-motion/01-introduction/02-about-the-platform"
          intent="white"
          size="small"
          className="no-underline"
          target="_blank"
        >
          Watch the platform features lesson
        </Button>
      </>
    ),
  },
  {
    title: "What if I get stuck?",
    content: () => (
      <p>
        Part of the PRO plan is access to our Discord community. On there both
        me and other members will always be glad to help you get unstuck.
      </p>
    ),
  },
  {
    title: "Is there a community that's part of this course?",
    content: () => (
      <p>
        Definitely. Part of the PRO plan is access to our Discord community.
        After purchase go to your profile for a link to join the community.
      </p>
    ),
  },
  {
    title: "Do I receive an invoice for this course?",
    content: () => (
      <p>
        Not for this course specifically, but you do get an invoice for the PRO
        membership. You receive this invoice from Stripe (my payment merchant)
        via email after purchase.
      </p>
    ),
  },
  {
    title: "Do you support Purchasing Power Parity for this course?",
    content: () => (
      <p>
        Yes I do! Not automated yet though. Please send me an email at{" "}
        <a href="mailto:jeroen@frontend.fyi" className="underline">
          jeroen@frontend.fyi
        </a>{" "}
        telling me where you live.
      </p>
    ),
  },
  {
    title: "Do you offer team licenses?",
    content: () => (
      <p>
        Yes. However, I don't have anything build for this yet. If you're
        interested in this, please shoot me an email at{" "}
        <a href="mailto:jeroen@frontend.fyi" className="underline">
          jeroen@frontend.fyi
        </a>
        . Since I'm right now building this platform, I can not afford building
        the team functionalities right away, without anyone asking for this. So
        your interest will only help speed this up.
      </p>
    ),
  },
  {
    title: "I have a question that isn't listed here.",
    content: () => (
      <p>
        Reach out to me via{" "}
        <a href="mailto:jeroen@frontend.fyi" className="underline">
          jeroen@frontend.fyi
        </a>{" "}
        and I get back to you as soon as possible.
      </p>
    ),
  },
];

export const FramerFaq = () => <Faq items={faqItems} />;
