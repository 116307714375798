import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDown } from "lucide-react";

export const Faq = ({
  items,
}: {
  items: { title: string; content: () => JSX.Element }[];
}) => (
  <Accordion.Root className="grid gap-2" type="multiple">
    {items.map(({ title, content: Content }) => (
      <Accordion.Item
        key={title}
        value={title}
        className="rounded-3xl bg-[#201726] text-white transition-colors hover:bg-[#1a121f]"
      >
        <Accordion.Trigger className="group grid w-full grid-cols-[60px_1fr] items-center gap-3 p-5 text-left">
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-[#120918] transition-transform group-hover:scale-110 group-focus:scale-110">
            <ChevronDown
              stroke="white"
              className="h-5 w-5 transition-transform [[data-state=open]_&]:rotate-180"
            />
          </span>
          {title}
        </Accordion.Trigger>
        <Accordion.Content className="text-md data-[state=open]:animate-radixSlideDown data-[state=closed]:animate-radixSlideUp overflow-hidden leading-relaxed">
          <div className="[[data-state=open]_&]:animate-fadeIn space-y-3 p-5 pt-0 opacity-0 [--fade-delay:100ms]">
            <Content />
          </div>
        </Accordion.Content>
      </Accordion.Item>
    ))}
  </Accordion.Root>
);
